import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"height":"360px"}
}
const _hoisted_2 = {
  class: "flex transparent",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_3 = {
  key: 0,
  class: "column self-end bg-white q-pa-sm q-mt-md left",
  style: {"width":"60%","height":"200px"}
}
const _hoisted_4 = {
  class: "col-1 Rotis text-h6",
  style: {"font-size":"25px","height":"40px"}
}
const _hoisted_5 = { class: "col text-dark" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  class: "flex transparent no-padding self-end",
  style: {"width":"100%","min-height":"360px"}
}
const _hoisted_8 = { class: "BigCardImageTextResponsiveStyle column card self-end bg-white q-pa-sm q-mb-md q-ml-md left" }
const _hoisted_9 = { class: "col Rotis text-h6 cardDecoration" }
const _hoisted_10 = { class: "col text-dark" }
const _hoisted_11 = { class: "col text-secondary cardDecoration right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.path === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_img, {
            style: {"width":"100%","height":"360px","background-repeat":"no-repeat","background-size":"cover"},
            src: _ctx.getImageSrc(_ctx.url || 'product-intrafix.jpg')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.title && _ctx.desc)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.desc), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.path !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_q_btn, {
            style: {"width":"100%"},
            to: _ctx.path,
            class: "no-padding card left",
            "no-caps": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_img, {
                class: "BigCardImageResponsiveStyle",
                style: {"width":"100%","background-repeat":"no-repeat","background-size":"cover"},
                src: _ctx.url
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.title), 1),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.desc), 1),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("SeeMore")), 1)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["src"])
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}