
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String
        },
        desc: {
            type: String
        },
        path: {
            type: String,
            default: null
        },
        url: {
            type: String
        }
    },
    data() {
        return {};
    },
    methods: {
        getImageSrc(url: string) {
            // If the URL is an external URL, return it as is
            if (url.startsWith('http')) {
                return url;
            }

            // Otherwise, try to require the image from the assets folder
            try {
                return require(`@/assets/${url}`);
            } catch (e) {
                return url; // Retourne l'URL telle quelle si `require` échoue
            }
        }
    }
});
