import TransportSolution from '../transports';

export interface IUser {
    'id': string,
    'username': string,
    'email': string,
    'provider': string,
    'confirmed': boolean,
    'blocked': boolean,
    'created_at': string
}

export interface IConversation {
    title: string,
    content: string,
    date: Date,
    author: IUser
}

export interface IRevision {
    status: string,
    date: Date,
    author: IUser
}

export interface IAddress {
    street: string,
    postalCode: string,
    city: string
}

export interface IMedicin {
    name: string,
    nameLong: string,
    activeAgent: string
}

export interface ICompatibilityData {
    preparation: string,
    description: string,
    minimalAgent: number,
    maximalAgent: number,
    minimalSolution: number,
    maximalSolution: number,
    agentConcentration: number,
    result4min: boolean,
    result60min: boolean,
    medicin: IMedicin,
    transportSolution: TransportSolution,
    baseContainerVolume: number,
    infusionType: string,
    volumeUnit: string,
    minCarrierSolutionInfusionRate: number,
    maxCarrierSolutionInfusionRate: number,
    minPreparedSolutionInfusionRate: number,
    maxPreparedSolutionInfusionRate: number,
    minConcentration: number,
    maxConcentration: number,
    minVolume: number,
    maxVolume: number,
    defaultSlider: number
}

export function compatibilityToJSON(raw: ICompatibilityData): any {
    return {
        preparation: raw.preparation,
        description: raw.description,
        minimalAgent: raw.minimalAgent,
        maximalAgent: raw.maximalAgent,
        minimalSolution: raw.minimalSolution,
        maximalSolution: raw.maximalSolution,
        agentConcentration: raw.agentConcentration,
        result4min: raw.result4min,
        result60min: raw.result60min,
        medicin: {
            name: raw.medicin?.name,
            nameLong: raw.medicin?.nameLong,
            activeAgent: raw.medicin?.activeAgent
        },
        transportSolution: raw.transportSolution?.id,
        baseContainerVolume: raw.baseContainerVolume,
        infusionType: raw.infusionType,
        volumeUnit: raw.volumeUnit,
        minCarrierSolutionInfusionRate: raw.minCarrierSolutionInfusionRate,
        maxCarrierSolutionInfusionRate: raw.maxCarrierSolutionInfusionRate,
        minPreparedSolutionInfusionRate: raw.minPreparedSolutionInfusionRate,
        maxPreparedSolutionInfusionRate: raw.maxPreparedSolutionInfusionRate,
        minConcentration: raw.minConcentration,
        maxConcentration: raw.maxConcentration,
        minVolume: raw.minVolume,
        maxVolume: raw.maxVolume,
        defaultSlider: raw.defaultSlider
    };
}

let checkRecursivAttribute: Function; // eslint-disable-line


export function fromDataType(raw: any) {
    const obj = { id: raw.id, ...raw.attributes };
    for (const attr in raw.attributes) {

        if (typeof obj[attr]?.data !== 'undefined') {
            if (Array.isArray(obj[attr].data)) {
                const arr = [];
                for (const p of obj[attr].data) {
                    arr.push(fromDataType(p));
                }
                obj[attr] = arr;
                continue;
            }
            obj[attr] = obj[attr].data ? fromDataType(obj[attr].data) : null;
            continue;
        }
        if (Array.isArray(obj[attr]) || typeof obj[attr] === 'object') {
            checkRecursivAttribute(obj[attr]);
        }
    }
    return obj;
}
checkRecursivAttribute = function(raw: any) {
    if (Array.isArray(raw) || typeof raw === 'object') {
        for (const p in raw) {
            if (Object.hasOwnProperty.apply(raw, [p])) {
                if (typeof raw[p]?.data !== 'undefined') {
                    if (Array.isArray(raw[p]?.data)) {
                        const rootRaw = raw[p]?.data;
                        raw[p] = [];
                        for (const pa of rootRaw) {
                            if (pa) raw[p].push(fromDataType(pa));
                        }
                        continue;
                    }
                    raw[p] = raw[p]?.data ? fromDataType(raw[p].data) : null;
                    continue;
                }
                if (Array.isArray(raw[p]) || typeof raw[p] === 'object') {
                    checkRecursivAttribute(raw[p]);
                }

            }
        }
    }
};

export function compatibilityFrom(raw: any): ICompatibilityData {
    const rawTransport = { ...(raw.transportSolution?.data?.attributes || raw.transportSolution), id: raw.transportSolution?.data?.id || raw.transportSolution?.id };
    return {
        preparation: raw.preparation,
        description: raw.description,
        minimalAgent: raw.minimalAgent,
        maximalAgent: raw.maximalAgent,
        minimalSolution: raw.minimalSolution,
        maximalSolution: raw.maximalSolution,
        agentConcentration: raw.agentConcentration,
        result4min: raw.result4min,
        result60min: raw.result60min,
        medicin: raw.medicin,
        transportSolution: rawTransport ? new TransportSolution(rawTransport.id, rawTransport.name, rawTransport.description, rawTransport.isContainer, rawTransport.isCoinfusion, '') : null,
        baseContainerVolume: raw.baseContainerVolume,
        infusionType: raw.infusionType,
        volumeUnit: raw.volumeUnit,
        minCarrierSolutionInfusionRate: raw.minCarrierSolutionInfusionRate,
        maxCarrierSolutionInfusionRate: raw.maxCarrierSolutionInfusionRate,
        minPreparedSolutionInfusionRate: raw.minPreparedSolutionInfusionRate,
        maxPreparedSolutionInfusionRate: raw.maxPreparedSolutionInfusionRate,
        minConcentration: raw.minConcentration,
        maxConcentration: raw.maxConcentration,
        minVolume: raw.minVolume || 0,
        maxVolume: raw.maxVolume || 0,
        defaultSlider: raw.defaultSlider
    } as ICompatibilityData;
};
