
import { defineComponent, Ref, ref } from 'vue';
import Compatibility from '@/model/compatibilities';
import { toFixedL } from '@/model/helper/reFixedNumber';
console.log(toFixedL);

interface dataI {

    // Medicin to test
  myMed: Ref<Compatibility>;
  test: Ref<Boolean>;

  // Model for container slider value
  containerModel: Ref<number>;

  // Models for coinfusion sliders values
  coinfusionModelSolution: Ref<number>;
  coinfusionModelAgent: Ref<number>;
  coinfusionModelAgentSolution: Ref<number>;

  // Compatibility result after 4/60 minutes
  displayResult4: Ref<Boolean>;
  displayResult60: Ref<Boolean>;

  // Model for volume slider value
  volumeModel: Ref<number>;

  // Default value for container volume
  containerVolumeList: Ref<number[]>;

  // Ratio for selected container volume / base container volume
  lastContainerVolume: Ref<number>;
  baseContainerVolumeRatio: Ref<number>;
  toFixedL: Function;
}

export default defineComponent({
    props: ['selectedMeds', 'med', 'compatibilityTab', 'perfusionWay', 'transport'],
    setup(props): dataI {
        return {
            myMed: ref(props.med),
            test: ref(true),
            containerModel: ref(props.med.calculation.minimalSolution / 2),
            coinfusionModelSolution: ref(props.med.calculation.minimalSolution / 2),
            coinfusionModelAgent: ref(props.med.calculation.minimalAgent / 2),
            coinfusionModelAgentSolution: ref(props.med.calculation.agentConcentration / 2),
            displayResult4: ref(false),
            displayResult60: ref(false),
            volumeModel: ref(props.med.calculation.baseContainerVolume ?? 500),
            containerVolumeList: ref([100, 250, 500, 625, 1000, 1250, 1825]),
            lastContainerVolume: ref(props.med.calculation.baseContainerVolume ?? 500),
            baseContainerVolumeRatio: ref(1),
            toFixedL
        };
    },
    beforeMount() {

        // Initialize each sliders with their mean values
        this.coinfusionModelSolution = (this.minimalSlider1 + this.maximalSlider1) / 2;
        this.coinfusionModelAgentSolution = this.med.calculation.defaultSlider || ((this.minimalSlider2 + this.maximalSlider2) / 2);
        this.coinfusionModelAgent = (this.minimalSlider3 + this.maximalSlider3) / 2;
        this.containerModel = (this.minimalSlider4 + this.maximalSlider4) / 2;
    },
    methods: {
        medInfosExpansion() {

            // Toggle the expansion of the med infos
            if (this.myMed.selected === false) {
                this.myMed.selected = true;
            } else {
                this.myMed.selected = false;
            }
        },
        transportSolutionConcentration(): string {
            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((Number(this.coinfusionModelSolution) / (Number(this.coinfusionModelAgent) + Number(this.coinfusionModelSolution)) * 100), 3);
            } else {
                return toFixedL((this.volumeModel / (this.volumeModel + Number(this.containerModel)) * 100), 3);
            }
        },
        activeAgentConcentration(): string {
            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((Number(this.coinfusionModelAgentSolution) * Number(this.coinfusionModelAgent) / (Number(this.coinfusionModelAgent) + Number(this.coinfusionModelSolution))), 3);
            } else {
                return toFixedL((Number(this.med.calculation.agentConcentration) * Number(this.containerModel) / (this.volumeModel + Number(this.containerModel))), 3);
            }
        },
        minSolutionConcentration(med?: Compatibility, volume?: number): string {
            const currentMed = med || this.med;
            const currentVolume = volume || this.volumeModel;

            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((currentMed.calculation.minimalSolution / (currentMed.calculation.maximalAgent + currentMed.calculation.minimalSolution) * 100), 3);
            } else {
                return toFixedL((currentVolume / (currentVolume + (currentMed.calculation.maximalSolution * this.baseContainerVolumeRatio)) * 100), 3);
            }
        },
        maxSolutionConcentration(med?: Compatibility, volume?: number): string {
            const currentMed = med || this.med;
            const currentVolume = volume || this.volumeModel;

            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((currentMed.calculation.maximalSolution / (currentMed.calculation.minimalAgent + currentMed.calculation.maximalSolution) * 100), 3);
            } else {
                return toFixedL((currentVolume / (currentVolume + (currentMed.calculation.minimalSolution * this.baseContainerVolumeRatio)) * 100), 3);
            }
        },
        minAgentConcentration(med?: Compatibility): string {
            const currentMed = med || this.med;

            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((currentMed.calculation.agentConcentration * currentMed.calculation.minimalAgent / (currentMed.calculation.minimalAgent + currentMed.calculation.maximalSolution)), 3);
            } else {
                return toFixedL(((currentMed.calculation.minimalSolution * this.baseContainerVolumeRatio) * (currentMed.calculation.agentConcentration) / (this.volumeModel + (currentMed.calculation.minimalSolution * this.baseContainerVolumeRatio))), 3);
            }
        },
        maxAgentConcentration(med?: Compatibility): string {
            const currentMed = med || this.med;

            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((currentMed.calculation.agentConcentration * currentMed.calculation.maximalAgent / (currentMed.calculation.maximalAgent + currentMed.calculation.minimalSolution)), 3);
            } else {
                return toFixedL(((currentMed.calculation.maximalSolution * this.baseContainerVolumeRatio) * Number(currentMed.calculation.agentConcentration) / (this.volumeModel + (currentMed.calculation.maximalSolution * this.baseContainerVolumeRatio))), 3);
            }
        },
        minMixingRatio(): string {
            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((this.med.calculation.agentConcentration * this.med.calculation.minimalAgent / (this.med.calculation.minimalAgent + this.med.calculation.maximalSolution)), 2);
            } else {
                return toFixedL((this.med.calculation.minimalSolution * this.baseContainerVolumeRatio * this.med.calculation.agentConcentration / (this.med.calculation.minimalSolution * this.baseContainerVolumeRatio + this.volumeModel)), 2);
            }
        },
        maxMixingRatio(): string {
            if (this.perfusionWay === 'coinfusion') {
                return toFixedL((this.med.calculation.agentConcentration * this.med.calculation.maximalAgent / (this.med.calculation.maximalAgent + this.med.calculation.minimalSolution)), 2);
            } else {
                return toFixedL((this.med.calculation.maximalSolution * this.baseContainerVolumeRatio * this.med.calculation.agentConcentration / (this.med.calculation.maximalSolution * this.baseContainerVolumeRatio + this.volumeModel)), 2);
            }
        },
        getAgentConcentration(): any[] {
            const concentrations: any[] = [];

            // For each meds which have the same name check if is between values
            /*
             * This.selectedMeds.filter((med: Compatibility) => med.calculation.medicin.activeAgent === this.med.calculation.medicin.activeAgent).forEach((med: Compatibility) => {
             *     concentrations.push({
             *         concentration: med.calculation.agentConcentration,
             *         volumeUnit: med.calculation.volumeUnit
             *     });
             * });
             */

            concentrations.push({
                concentration: this.myMed.calculation.agentConcentration,
                volumeUnit: this.myMed.calculation.volumeUnit
            });
            return concentrations;
        },
        getConcentrations(): any[] {
            const concentrations: any[] = [];

            // For each meds which have the same name check if is between values
            /*
             * this.selectedMeds.filter((med: Compatibility) => med.calculation.medicin.activeAgent === this.med.calculation.medicin.activeAgent).forEach((med: Compatibility) => {
             *     concentrations.push({
             *         concentration: this.perfusionWay === 'container' ? med.calculation.baseContainerVolume : toFixedL(Number(med.calculation.agentConcentration), 2),
             *         volumeUnit: this.perfusionWay === 'container' ? 'ml' : med.calculation.volumeUnit
             *     });
             * });
             */

            concentrations.push({
                concentration: this.perfusionWay === 'container' ? this.myMed.calculation.baseContainerVolume : toFixedL(Number(this.myMed.calculation.agentConcentration), 2),
                volumeUnit: this.perfusionWay === 'container' ? 'ml' : this.myMed.calculation.volumeUnit
            });
            return concentrations;
        },
        getIntervals(): string[][][] {
            const intervals: string[][][] = [];

            // For each meds which have the same name return intervals
            /*
             * This.selectedMeds.filter((med: Compatibility) => med.calculation.medicin.activeAgent === this.med.calculation.medicin.activeAgent).forEach((med: Compatibility) => {
             *     intervals.push([
             *         [toFixedL(Number(med.calculation.minimalSolution), 1), toFixedL(Number(med.calculation.maximalSolution), 1)],
             *         [toFixedL(Number(med.calculation.minimalAgent), 2), toFixedL(Number(med.calculation.maximalAgent), 2)]
             *     ]);
             * });
             */

            intervals.push([
                [toFixedL(Number(this.myMed.calculation.minimalSolution), 2), toFixedL(Number(this.myMed.calculation.maximalSolution), 2)],
                [toFixedL(Number(this.myMed.calculation.minimalAgent), 2), toFixedL(Number(this.myMed.calculation.maximalAgent), 2)]
            ]);

            console.log(intervals);

            return intervals;
        }
    },
    computed: {

        // Bornes des différents sliders
        minimalSlider1(): number {
            return this.myMed.calculation.minCarrierSolutionInfusionRate;
        },
        maximalSlider1(): number {
            return this.myMed.calculation.maxCarrierSolutionInfusionRate;
        },
        minimalSlider2(): number {
            return this.myMed.calculation.minConcentration;
        },
        maximalSlider2(): number {
            return this.myMed.calculation.maxConcentration;
        },
        minimalSlider3(): number {
            return this.myMed.calculation.minPreparedSolutionInfusionRate;
        },
        maximalSlider3(): number {
            return this.myMed.calculation.maxPreparedSolutionInfusionRate;
        },
        minimalSlider4(): number {
            return this.myMed.calculation.minVolume;
        },
        maximalSlider4(): number {
            return this.myMed.calculation.maxVolume;
        },

        // Step des différents sliders
        stepSlider1(): number {
            return (this.maximalSlider1 - this.minimalSlider1) / 100;
        },
        stepSlider2(): number {
            return (this.maximalSlider2 - this.minimalSlider2) / 100;
        },
        stepSlider3(): number {
            return (this.maximalSlider3 - this.minimalSlider3) / 100;
        },
        stepSlider4(): number {
            return (this.maximalSlider4 - this.minimalSlider4) / 100;
        },

        formatedVolumeUnit(): string {
            return this.med.calculation.volumeUnit.replace(/\//g, ' / ');
        },
        medSelection(): boolean {
            return this.myMed.selected;
        },

        // Return a boolean indicating if the current value is between the min and max values
        isBetween(): boolean {

            // Return a boolean indicating if the current value is between the min and max values
            const solutionConcentration = Number(this.transportSolutionConcentration());
            const solutionAgent = Number(this.activeAgentConcentration());
            let result = false;

            // For each meds which have the same name check if is between values
            this.selectedMeds.filter((med: Compatibility) => med.calculation.medicin.activeAgent === this.med.calculation.medicin.activeAgent).forEach((med: Compatibility) => {
                if (solutionConcentration >= Number(this.minSolutionConcentration(med)) &&
                    solutionConcentration <= Number(this.maxSolutionConcentration(med)) &&
                    solutionAgent >= Number(this.minAgentConcentration(med)) &&
                    solutionAgent <= Number(this.maxAgentConcentration(med))) {
                    result = true;
                    this.displayResult4 = med.calculation.result4min;
                    this.displayResult60 = med.calculation.result60min;
                }
            });
            return result;
        }
    },
    watch: {
        coinfusionModelSolution(newValue: String) {
            this.coinfusionModelSolution = Number(String(newValue).replace(',', '.'));
            if (this.coinfusionModelSolution < this.minimalSlider1) {
                this.coinfusionModelSolution = this.minimalSlider1;
            }
            if (this.coinfusionModelSolution > this.maximalSlider1) {
                this.coinfusionModelSolution = this.maximalSlider1;
            }
            this.coinfusionModelSolution = parseFloat(toFixedL(this.coinfusionModelSolution, 4));
        },
        coinfusionModelAgentSolution(newValue: String) {
            this.coinfusionModelAgentSolution = Number(String(newValue).replace(',', '.'));
            if (this.coinfusionModelAgentSolution < this.minimalSlider2) {
                this.coinfusionModelAgentSolution = this.minimalSlider2;
            }
            if (this.coinfusionModelAgentSolution > this.maximalSlider2) {
                this.coinfusionModelAgentSolution = this.maximalSlider2;
            }
            this.coinfusionModelAgentSolution = parseFloat(toFixedL(this.coinfusionModelAgentSolution, 4));
        },
        coinfusionModelAgent(newValue: String) {
            this.coinfusionModelAgent = Number(String(newValue).replace(',', '.'));
            if (this.coinfusionModelAgent < this.minimalSlider3) {
                this.coinfusionModelAgent = this.minimalSlider3;
            }
            if (this.coinfusionModelAgent > this.maximalSlider3) {
                this.coinfusionModelAgent = this.maximalSlider3;
            }
            this.coinfusionModelAgent = parseFloat(toFixedL(this.coinfusionModelAgent, 4));
        },
        containerModel(newValue: String) {
            let rawValue = Number(String(newValue).replace(',', '.'));

            if (rawValue < this.minimalSlider4) {
                rawValue = this.minimalSlider4;
            }
            if (rawValue > this.maximalSlider4) {
                rawValue = this.maximalSlider4;
            }
            this.containerModel = parseFloat(toFixedL(rawValue, 4));
        },
        medSelection(newValue: boolean) {
            this.$emit('setIsOpen', newValue);
        },

        // Update ratio from baseContainerVolume to selected volume
        volumeModel(newValue: String) {
            this.baseContainerVolumeRatio = Number(String(newValue)) / this.myMed.calculation.baseContainerVolume;
            this.containerModel *= Number(String(newValue)) / this.lastContainerVolume;

            this.lastContainerVolume = Number(String(newValue));
        }
    },
    mounted() {
        if (!this.containerVolumeList.includes(this.med.calculation.baseContainerVolume)) {
            this.containerVolumeList.push(this.med.calculation.baseContainerVolume);
            this.volumeModel = this.med.calculation.baseContainerVolume;
        }
    }
});
